// config
import "./__variables.scss";

// independent snippets
import "./__fonts.scss";
import "./__general.scss";
import "./__debug.scss";
import "./__stripes.scss";

// modules
import "./_content_element.scss";
import "./_content_2_columns.scss";
import "./_page_intro.scss";
import "./_reference.scss";
import "./_feature.scss";
import "./_people_list.scss";
import "./_workshop_programm.scss";
import "./_programm.scss";
import "./_kursplan.scss";
import "./_slideshow.scss";
import "./_studio.scss";
import "./_page_intro_magazine.scss";
import "./_content_element_magazine.scss";
import "./_performance_projekte_profitraining.scss";
import "./_festival_archiv.scss";
import "./_festival_logos.scss";
import "./_newsletter.scss";
import "./_festival_programm.scss";
import "./_today.scss";
import "./_video.scss";
import "./_accordeon_element.scss";
import "./_accordeon_headline.scss";
import "./_image_element_magazine.scss";
import "./_submenu_divider.scss";
import "./_vorschalt.scss";

// other views
import "./start.scss";
import "./single.scss";
import "./footer.scss";
import "./side_menu.scss";
import "./main_nav.scss";
import "./trix.scss";
import "./header.scss";
import "./flash.scss";
import "./event_modal.scss";

// other snippets
import "./archive_links.scss";

// booking widget
import "./booking_widget";

// user account management
import "./user_account";
